<template>
  <v-container fluid>
    <v-toolbar class="mb-2" dark color="blue-grey darken-2">
      <v-toolbar-title>My Journal</v-toolbar-title>
        <v-btn
          fab
          color="blue-grey lighten-1"
          right
          absolute
          @click="newEntry()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-toolbar>

    <v-timeline
      :reverse="reverse"
      dense
      v-if="journal_entries.length > 0"
    >
      <v-timeline-item
        v-for="entry in journal_entries"
        :key="entry._id"
      >
        <v-card
            class="mx-auto"
            outlined
        >
          <v-system-bar window>
            <span class="mr-2">{{ entry.createDate | simpleDate }}</span>
            <div> {{ entry.label | digest}} </div>
            <v-spacer></v-spacer>
            <v-icon
              @click="deleteConfirm(entry)"
            >
              mdi-delete-empty
            </v-icon>
          </v-system-bar>
          <div 
              @click="openEntry(entry)"
            >
              <vue-markdown
                class="ma-2 caption"
                :watches="['source']"
                placeholder="Empty jounral..."
                :source="entry.text | digest"
              >
              </vue-markdown>
            </div>
            <v-card-actions>
                <div class="caption font-weight-thin">Updated: {{ entry.updateDate | lastUpdated}}</div>
            </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
      <v-card class="mx-auto">
        <v-card-title>
          <span class="headline">Really delete?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
          <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="entryScreen" max-width="1200px" >
      <v-card >
        <v-system-bar height="40" window class="indigo darken-2">
          <v-text-field dense dark placeholder="Entry label (optional)" v-model="currentEntry.label"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-4" @click="close">Close</v-btn>
          <v-btn small color="blue darken-1" @click="save">Save</v-btn>
        </v-system-bar>
        <v-container>
          <v-icon @click="edit = !edit">mdi-pencil</v-icon>
          <v-row>
            <v-col v-if="edit">
              <v-textarea
                clearable
                auto-grow
                class="caption"
                label="Entry Source"
                v-model="currentEntry.text"
                outlined
                hint="Feel free to use markdown"
              ></v-textarea>
            </v-col>
            <v-col class="caption text-left mt-3 mb-11">
              <vue-markdown
                :watches="['source']"
                placeholder="Empty entry..."
                :source="currentEntry.text"
              >
              </vue-markdown>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueMarkdown from 'vue-markdown' // production
import moment from 'moment'

export default {
  data () {
    return {
      entryScreen: false,
      snack: false,
      edit: false,
      snackColor: '',
      snackText: '',
      deleteDialog: false,
      entrySelected: false,
      journalScreen: false,
      editing: false,
      entry: "",
      editedIndex: -1,
      currentEntry: {
        _id: '',
        label: '',
        text: ''
      },
      defaultItem: {
        _id: '',
        label: '',
        text: ''
      }
    }
  },
  components: {
    VueMarkdown
  },
  props: {
    mode: String
  },
  methods: {
    ...mapActions(['fetchJournalEntries']),
    ...mapActions(['addJournalEntry']),
    ...mapActions(['deleteJournalEntry']),
    ...mapActions(['updateJournalEntry']),
    deleteConfirm (entry) {
      this.editedIndex = this.journal_entries.indexOf(entry)
      this.currentEntry = Object.assign({}, entry)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteJournalEntry(this.currentEntry)
      this.close()
    },
    close () {
      this.entryScreen = false
      this.deleteDialog = false
      setTimeout(() => {
        this.currentEntry = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateJournalEntry(this.currentEntry)
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      } else {
        this.addJournalEntry(this.currentEntry)
        .then((value) => {
          if (value[0] === "Success") {
            this.editedIndex = this.journal_entries.indexOf(value[1])
            this.currentEntry = Object.assign({}, value[1])
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
          }
        })
      }
      this.edit = false
      // this.close()
    },
    openEntry (entry) {
      this.edit = false
      this.entryScreen = true
      this.editedIndex = this.journal_entries.indexOf(entry)
      this.currentEntry = Object.assign({}, entry)
    },
    newEntry () {
      this.currentEntry = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.edit = true
      this.entryScreen = true
    }
  },
  computed: {
    ...mapGetters({
      journal_entries: 'all_journal_entries'
    }),
    filteredNotes(){
      return this.notes.filter(note => {
        if (this.selectedCategories.length === 0) {
          return note
        } else {
          if (this.selectedCategories.includes(note.category.label)) {
            return note
          }
        }
      })
    }
  },
  mounted () {
    this.fetchJournalEntries()
  },
  watch: {
  },
  filters: {
    digest (note) {
      return (note.length >= 40) ? note.substring(0, 40) + ' ...' : note
    },
    label (category) {
      return (typeof category === 'undefined') ? 'Not categorized' : category
    },
    lastUpdated (date) {
      var temp = moment(date).format('DD/MM/YYYY, h:mm:ss a');
      return moment(temp, 'DD/MM/YYYY, h:mm:ss a').fromNow()
    },
    simpleDate (date) {
      return moment(date).format('DD/MM/YYYY');
    }
  }
}
</script>

<style scoped>

</style>
