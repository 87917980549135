import Vue from 'vue'
import VueRouter from 'vue-router'
import Automation from '@/views/Automation'
import HaabTasks from '@/views/HaabTasks'
import Setup from '@/views/HaabSetup'
import Dashboard from '@/views/Dashboard'
import Journal from '@/views/Journal'
import Tasks from '@/views/lists/Tasks'
import Shopping from '@/views/lists/Shopping'
import Notes from '@/views/notes/Notes'
import Admin from '@/views/Admin'
import Register from '@/views/Register'
import Profile from '@/views/Profile'
import Login from '@/views/Login'
import About from '@/views/About'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      guest: true
    }
  },
  {
    path: '/user/register',
    name: 'Register',
    props: true,
    component: Register,
    meta: {
      guest: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    props: true,
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/automation',
    name: 'Automation',
    component: Automation,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: Shopping,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/journal',
    name: 'Journal',
    component: Journal,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/haab/setup',
    name: 'Setup',
    props: true,
    component: Setup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/haab/tasks',
    name: 'Haab Tasks',
    props: true,
    component: HaabTasks,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/admin',
    name: 'Admin',
    props: true,
    component: Admin,
    meta: {
      // requiresAuth: true,
      isAdmin: true
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwtToken') === null) {
      next({
        path: '/login',
        params: {nextUrl: to.fullPath}
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwtToken') === null) {
      next()
    } else {
      next({name: 'Dashboard'})
    }
  } else if (to.matched.some(record => record.meta.isAdmin)) {
    if (store.getters.myUser.admin === true) {
      next()
    } else {
      next({name: 'Login'})
    }
  } else {
    next()
  }
})

export default router
