<template>
  <v-container>
    <v-card
    class="mx-auto elevation-6"
    max-width="700"
    outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">{{user.name}}</div>
          <v-list-item-title class="headline mb-1">{{user.email}}</v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
         @click="passUpdateDiag = true"
          tile
          size="120"
          color="grey"
          class="elevation-6"
        >
          <v-img :src="myAvatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-text ref="textToCopy">
        <v-text-field
          label="Apikey"
          outlined 
          v-model="user.apikey"
          ref="keyToCopy"
          append-outer-icon="mdi-clipboard-multiple-outline"
          @click:append-outer="copyKey"
          >
        </v-text-field>
        <v-text-field
          label="New Apikey"
          outlined 
          v-model="user.shakey"
          ref="keyToCopy"
          append-outer-icon="mdi-clipboard-multiple-outline"
          @click:append-outer="copyKey"
          >
        </v-text-field>
      </v-card-text>
      <v-alert
        v-for="error in errors"
        v-bind:key="error._id"
        border="left"
        close-text="Close Alert"
        color="#2A3B4D"
        dense
        dark
        dismissible
      >
        {{ error.message }}
      </v-alert>
      <v-card-text>
        <v-divider></v-divider>
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="input-10-1"
            label="Enter a new password"
            hint="At least 8 characters"
            counter
            @click:append="show = !show"
          ></v-text-field>
          <v-text-field
            v-model="passwordRepeat"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.match]"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Repeat the same password"
            hint="Passwords must match"
            class="input-group--focused"
            @click:append="show = !show"
          ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="error" @click="updatePassword">Update password</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="passUpdateDiag" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Avatar update</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-file-input
              v-model="file"
              placeholder="Upload your avatar"
              label="Avatar"
              prepend-icon="mdi-camera"
              :rules="[rules.size]"
              accept="image/*"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-container>
          <small>Maximum size: {{maxSize}} bytes</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="upload">Update avatar</v-btn>
          <v-btn color="blue darken-1" text @click="passUpdateDiag = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '../../config/index.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Profile',
  components: {
  },
  data () {
    return {
      errors: [ ],
      show: false,
      passUpdateDiag: false,
      file: null,
      maxSize: 1500000,
      email: '',
      passwordRepeat: '',
      password: '',
      apikey: '',
      isConnected: false,
      defaultAvatar: require('@/assets/default_avatar.jpg'),
      rules: {
        size: value => !value || value.size < 1500000 || 'Avatar size should be less than 2 MB!',
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        match: v => v == this.password || 'Passwords must match',
        emailMatch: () => ('The email and password you entered don\'t match'),
      },
    }
  },
  methods: {
    ...mapActions(['fetchMyUser']),
    updatePassword () {
      if (this.password === this.passwordRepeat) {
        this.errors = []
        axios.post(config.api.url + ':' + config.api.port + `/users/update/`, {
          email: this.email,
          password: this.password,
          password2: this.passwordRepeat
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
          .then(response => {
            this.errors.push({
              _id: this.errors.length,
              message: response.data.message
            })
            this.password = ""
            this.passwordRepeat = ""
          })
          .catch(e => {
            this.errors.push(e)
          })
      }
    },
    upload () {
      var formData = new FormData()
      formData.append('file',this.file)
      axios.post(config.api.url + ':' + config.api.port + `/users/upload/`,
        formData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'content-type': 'multipart/form-data'
        }
      })
        .then(response => {
          this.trash=response
          this.passUpdateDiag = false
          this.fetchMyUser()
          this.file = null
        })
        .catch(e => {
          this.trash = e
          this.passUpdateDiag = false
          // this.errors.push(e)
        })
    },
    copyKey () {
      let textToCopy = this.$refs.keyToCopy.$el.querySelector('input')
      textToCopy.select()
      document.execCommand("copy");
    }
  },
  computed: {
    ...mapGetters({
      user: 'myUser'
    }),
    state () {
      if (this.password.length > 0) {
        return true
      } else {
        return false
      }
    },
    validPass () {
      if (this.repPassword === this.password) {
        return ''
      } else {
        return 'Passwords must match'
      }
    },
    invalidPass () {
      if (this.repPassword !== this.password) {
        return 'Passwords must match'
      } else {
        return ''
      }
    },
    myAvatar () {
      return config.api.url + ':' + config.api.port + `/`+this.user.avatar
    }
  },
  props: {
  },
  mounted () {
  },
  created () {
    this.token = localStorage.getItem('jwtToken')
  },
}
</script>

<style scoped>

</style>
