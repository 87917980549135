<template>
  <v-container>
    <v-row>
      <v-toolbar elevation-3 color="blue-grey darken-2" dark dense>
        <v-toolbar-title>Notes</v-toolbar-title>
        <v-icon class="pl-3" @click="newNote">mdi-note-plus</v-icon>
      </v-toolbar>
      <v-dialog v-model="catdialog" max-width="600px" max-heigth="300px">
        <v-card class="mx-auto">
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-col>
              <v-text-field v-model="editedItem.label" label="Category"></v-text-field>
            </v-col>
          </v-card-text>
        <v-color-picker v-model="editedItem.color" class="ma-2" width="600" hide-inputs></v-color-picker>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon v-if="editedIndex >= 0" @click="deleteConfirm()">mdi-delete</v-icon>
            <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
        <v-card class="mx-auto">
          <v-card-title>
            <span class="headline">Really delete?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
            <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snack"
        :timeout="3000"
      >
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snack = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
    <v-row>
      <span class="buttom mt-2 mr-2">Categories:</span>
      <v-icon @click="catdialog = !catdialog">mdi-tag-plus</v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-cog</v-icon>
        </template>
        
        <v-list >
          <v-subheader>EDIT CATEGORIES</v-subheader>
          <v-list-item
            v-for="category in categories"
            :key="category._id"
            @click="editItem(category)"
          >
            <v-list-item-title>{{ category.label}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>

      <span
          v-for="category in categories"
          :key="category._id">
        <v-chip
          dark
          label
          class="ma-2 elevation-3"
          :color="category.color"
          :small="!category.selected"
          @click="clickedCategory(category)"
        >
          <v-icon v-if="category.selected">mdi-check</v-icon>
          {{category.label}}
        </v-chip>
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    deleteDialog: false,
    selectedCategories: [],
    catdialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    editedIndex: -1,
    editedItem: {
      label: '',
      color: ''
    },
    defaultItem: {
      label: '',
      color: ''
    }
  }),
  methods: {
    ...mapActions(['fetchCategories']),
    ...mapActions(['fetchNotes']),
    ...mapActions(['addCategory']),
    ...mapActions(['deleteCategory']),
    ...mapActions(['updateCategory']),
    editItem (item) {
      this.editedIndex = this.categories.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.catdialog = true
    },
    deleteConfirm () {
      // this.editedItem = Object.assign({}, item)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteCategory(this.editedItem)
      this.close()
    },
    clickedCategory (category) {
      category.selected =! category.selected
      if (!this.selectedCategories.includes(category.label)) {
        this.selectedCategories.push(category.label)
      } else {
        var index = this.selectedCategories.indexOf(category.label)
        if (index > -1) {
          this.selectedCategories.splice(index, 1);
        }
      }
      this.$emit('categoryFilter' , this.selectedCategories)
    },
    close () {
      this.catdialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateCategory(this.editedItem)
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      } else {
        this.addCategory(this.editedItem)
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      }
      this.close()
      this.fetchNotes()
    },
    newNote () {
      this.$emit('newNote')
    }
  },
  computed: {
    ...mapGetters({
      categories: 'allCategories'
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Category' : 'Edit Category'
    },
  },
  mounted () {
    this.fetchCategories()
  }
}
</script>

<style>

</style>