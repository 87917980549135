import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  journal_entries: []
}

const getters = {
  all_journal_entries: (state) => state.journal_entries
}

const actions = {
  async fetchJournalEntries ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/journal', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setJournal', response.data.journal_entries)
    commit('sortJournalEntries')
  },
  async addJournalEntry ({ commit }, journal_entry) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/journal/add`, {
      label: journal_entry.label,
      text: journal_entry.text
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // TODO: need to check response, because it can obiously fail
    // Maybe can be checked and later the HTTP CODE sent to the interface. The interface will way 200 for success and every other should be a fail?
    commit('pushJournalEntry', response.data.journal_entry)
    commit('sortJournalEntries')
    return(["Success", response.data.journal_entry])
  },
  async deleteJournalEntry ({ commit }, journal_entry) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/journal/delete`, {
      journal_entry
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (response.data.message === "Deleted")
      commit('remJournalEntry', journal_entry)
  },
  async updateJournalEntry ({ commit }, journal_entry) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/journal/update`, {
      journal_entry: {
        _id: journal_entry._id,
        label: journal_entry.label,
        text: journal_entry.text,
      }
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (response.data.message === "Journal updated") {
      commit('remJournalEntry', journal_entry)
      commit('pushJournalEntry', response.data.journal_entry)
      commit('sortJournalEntries')
    }
  }
}

const mutations = {
  setJournal: (state, journal_entries) => (state.journal_entries = journal_entries),
  pushJournalEntry: (state, journal_entry) => (state.journal_entries.push(journal_entry)),
  remJournalEntry: (state, deljournal_entry) => (state.journal_entries = state.journal_entries.filter(journal_entry => journal_entry._id !== deljournal_entry._id)),
  sortJournalEntries: (state) => {
    state.journal_entries = state.journal_entries.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
